.woms-modal-fullscreen
{
    width: 95vw;
    height: 90vh;
    margin: 2vh auto 0 auto;
}

.woms-modal-fullscreen .modal-content
{
    // border-radius: #{$modal-content-border-radius};
    border-radius: 1rem;
    border: 1px solid rgb(229, 231, 235);
}

.woms-modal-button-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.woms-modal-button-container {
text-align: right;
}

.cost-price-modal {
    max-width: 60%; 
}