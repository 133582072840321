.woms-icon-scale {
    scale: 1.2;
    &:hover {
        color:$primary;
    transform: scale(1.2);
    transition: all 0.2s
    }
    border-radius: 1rem;
}

[data-layout-mode="dark"] {

 i {
    color:rgb(229, 231, 235); // side bar icon color.
}
}

.message-icon {
    border-radius: 50%;
    border: 1px solid grey;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}


