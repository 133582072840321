/* ProductBasicInfo */ 
.woms-product-basic-info-img {
    @media (max-width:1200px) {
        width: 35% !important;
    }

    @media(max-width:992px) {
        width: 50% !important
    }
}

.woms-product-basic-info-img-col {
    display: flex;
    justify-content: center; 
    align-items: center;
}

/* ProductImages */
.woms-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    place-items:center;
    grid-template-areas:"image-overlay";

    @media (max-width:1200px){
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width:576px) {
        grid-template-columns: repeat(3,1fr);
    }
}
.woms-grid-item-content {
    grid-area: image-overlay;
    background-color: #fcfcfc;
    border: 0.5px solid #ccc;
}
.woms-grid-item-overlay {
    grid-area: image-overlay;
    background-color: rgba(27,33,38,0.5);
    display:flex;
    justify-content:center;
    align-items: center;
    
}
.woms-grid-item-overlay-text {
    color:white;
    font-size:28px;
    font-weight: 500;
    @media(max-width:1400px) {
        font-size:22px;
    }
    @media (max-width:1200px)
    {
        font-size:20px;
    }
    @media (max-width:992px)
    {
        font-size:30px;
    }
    @media(max-width:768px)
    {
        font-size:24px;
    }
    @media(max-width:576px)
    {
        font-size:26px;
    }
}

.woms-grid-item {
    display: grid;
    cursor:pointer;
}

.woms-grid-img {
     width: 100%;
     height:100%;
     object-fit:contain;
     aspect-ratio: 1;
}





