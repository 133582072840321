.price-mod-row {
  height: 32px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .price-mod-row {
    max-width: 400px;
  }
}

.price-mod-item-label {
  width: 100px;
  text-align: right;
}

.price-mod-input-container {
  width: 100%;
  height: 100%;
}

.price-mod-add-button-oli {
  @extend .bg-light;
  height: 24px;
  &:hover {
    background-color: rgba($color: #cacaca, $alpha: 1.0);
  }
}

.price-mod-add-button {
  height: 24px;
}

.edit-order-edit-button {
  height: 14px;
  width: 14px;
  &:disabled {
    background-color: white;
  }
}

.price-mod-name-input {
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-order-item-button {
  @extend .bg-light-subtle;
  &:hover {
    background-color: black;
  }
}

.order-line-items-container {
  @media (max-width: 1000px) {
    overflow-x: auto;
   
  }
}

.oli-row-typeahead {
  min-width: 220px;
}

.oli-price-x-quantity-input {
  max-width: 100px;
}

.oli-price-container {
  width: 6rem;
}

.price-mod-section {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 1200px) {
  .price-mod-section {
    width: 20rem;
    
  }
}


.oli-row-element {
  height: 32px;
}

.oli-typeahead-menu-item {
  min-width: 300px;
  width: 500px;
}

.input-border-color {
  border: 1px solid var(--bs-input-border-color) !important;
}

.quantity-input {
  min-width: 80px;
}