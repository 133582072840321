
.form-control.is-invalid.css-13cymwt-control{
    border-color: #f46a6a !important;
    padding-right: calc(1.5em + 0.94rem) !important;
    
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f46a6a%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f46a6a%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.235rem) center !important;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem) !important;
}

.css-13cymwt-control{
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.css-t3ipsp-control{
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}
