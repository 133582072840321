.icon-add {
    color: #50a5f1;
    font-size: 16px;
}

.icon-remove {
    color: #f46a6a;
    font-size: 16px;
}

.array-item {
    margin-bottom: 0.5rem;
}

.formik-error-message-color{
    color: red;
}
