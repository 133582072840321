.react-tel-input .form-control {
    width: 100%;
    padding: 0.45rem 0.75rem 0.45rem 3.5rem;
    border: 1px solid var(--bs-input-border-color);
    font-size: 0.8125rem;
    font-weight: 400;
}

.react-tel-input .form-control.is-invalid{
    border-color: #f46a6a;
}

.react-tel-input .flag-dropdown.open .country-list .search input {
    margin: 0;
    padding: 0.47rem 0.75rem;
    width: 97%;
    font-size: 16px;
}



