/* scroll box shadow effect */
.woms-scrollable-box-shadow {
    max-height: 50vh; 
    overflow-y: auto;
    background-image: 
        linear-gradient(to top, white, white),
        linear-gradient(to bottom, white, white),
        linear-gradient(to top, rgba(100, 100, 100, 0.25), rgba(208, 208, 208, 0)),
        linear-gradient(to bottom, rgba(100, 100, 100, 0.25), rgba(208, 208, 208, 0));
    background-position: bottom center, top center, bottom center, top center;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
}

[data-layout-mode="dark"] {
    $dark-gray-200: #2a3042; // modal body dark background color

    .woms-scrollable-box-shadow {
        background-color:$dark-gray-200;
        background-image:
            linear-gradient(to top, $dark-gray-200, $dark-gray-200),
            linear-gradient(to bottom, $dark-gray-200, $dark-gray-200),
            linear-gradient(to top, rgba(100, 100, 100, 0.25), rgba(208, 208, 208, 0)),
            linear-gradient(to bottom, rgba(100, 100, 100, 0.25), rgba(208, 208, 208, 0));        

    }
}

/* Checkbox size */
.woms-checkbox-lg .form-check-input {
    top: 0.8rem;
    scale:1.4;
    margin-right: 0.7rem;
}

/* Search bar clear button */
// input[type="search"]::-webkit-search-cancel-button {
//     -webkit-appearance: none;
//     display: inline-block;
//     width: 12px;
//     height: 12px;
//     margin-left: 10px;
//     background:
//       linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#000 45%,#000 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
//       linear-gradient(135deg, transparent 0%,transparent 43%,#000 45%,#000 55%,transparent 57%,transparent 100%);
//   }
  
/* edit btn */
.woms-edit-btn {
    float:right;
    background-color: transparent;
    border-color: transparent;
    max-height: 32px;
    max-width: 32px;
}

.woms-config-btn {
    color: rgba(90, 90, 90, 1);
    background-color: transparent;
    border-color: transparent;
}
/* edit btn */
.woms-avatar-btn {
    margin-top:-35px;
    margin-left:-10px;
    background-color: transparent;
    border-color: transparent;
}
.woms-product-placeholder {
    font-size:45px;
    color:lightgray;
}
.woms-sku-placeholder {
    font-size:30px;
    color:lightgray;
}
.woms-lineitem-table {
    --bs-table-bg: transparent;
}

.woms-lineitem-edit-table {
    --bs-table-bg: rgba(0,0,0,0);
}

.woms-activitylog-avatar {
    position: absolute;
    top: 4px;
    left: 10px;
}

.divider {
    margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--#{$prefix}gray-500);;
}

.woms-reply-btn {
    background-color: transparent;
    border-color:transparent;
}

.opaque-disabled-btn {
    &:disabled,
    &.disabled,
    fieldset:disabled & {
      opacity: 1;
    }
}

.plan-title {
 min-height: 50px;
}

.plan-desc {
 min-height: 120px;
}

.billing-table {
    width: fit-content;
}

.billing-table table {
    --bs-table-border-color:lightgray !important;
}
.billing-table td,
.billing-table th  {
    vertical-align: middle;
    padding-right:40px;
}